import post1 from './posts/1';
// import post2 from './posts/2';
// import post3 from './posts/3';
import post4 from './posts/4';
import post5 from './posts/5';
import post6 from './posts/6';
import post7 from './posts/7';
import post8 from './posts/8';
import post9 from './posts/9';
import post10 from './posts/10';
import post11 from './posts/11';
import post12 from './posts/12';
import post13 from './posts/13';
import post14 from './posts/14';
import post15 from './posts/15';
import post16 from './posts/16';
import post17 from './posts/17';
import post18 from './posts/18';
import post19 from './posts/19';
import post20 from './posts/20';
import post21 from './posts/21';
import post22 from './posts/22';
import post23 from './posts/23';
import post24 from './posts/24';
import post25 from './posts/25';
import post26 from './posts/26';
import post27 from './posts/27';
import post28 from './posts/28';
import post29 from './posts/29';
import post30 from './posts/30';
import post31 from './posts/31';
import post32 from './posts/32';

import { Post } from './types';
import post33 from './posts/33';
import post34 from './posts/34';
import post35 from './posts/35';
import post36 from './posts/36';
import post37 from './posts/37';
import post38 from './posts/38';
import post39 from './posts/39';
import post40 from './posts/40';
import post41 from './posts/41';
import post42 from './posts/42';
import post43 from './posts/43';
import post44 from './posts/44';
import post45 from './posts/45';

export const posts: Post[] = [
  post45,
  post44,
  post43,
  post42,
  post41,
  post40,
  post39,
  post38,
  post37,
  post36,
  post35,
  post34,
  post33,
  post32,
  post31,
  post30,
  post29,
  post28,
  post27,
  post26,
  post25,
  post24,
  post23,
  post22,
  post21,
  post20,
  post19,
  post18,
  post17,
  post16,
  post15,
  post14,
  post13,
  post12,
  post11,
  post10,
  post9,
  post8,
  post7,
  post6,
  post5,
  post4,
  post1,
];

export const relatedPosts = {
  'introducing-lease-bandit-your-gateway-to-car-leasing-at-unbeatable-prices': [
    post6,
    post5,
    post4,
  ],
  'ultimate-car-leasing-guide-beginners-need': [post25, post24, post23],
  'best-comprehensive-car-leasing-guide': [post18, post19, post20],
  'car-leasing-101-comprehensive-beginners-guide': [post16, post17],
  'guide-to-car-leasing-new-york-city': [post31, post29, post26],
  'unlocking-pa-car-leasing': [post28, post14, post15],
  'guide-to-new-jersey-car-lease-landscape': [post10, post11, post27],
  'car-lease-terms-explained': [post6, post5, post4],
  'how-to-lease-car-in-california': [post30, post8],
  'easing-a-car-in-ct-tips': [post7, post1],
  'what-is-lease-cash': [post6, post5, post4],
  'leasing-car-online': [post35, post34, post33],
  'leasing-vs-financing-a-car': [post32, post21, post22],
  'how-to-buy-car-in-california-guid': [post34, post36],
  'how-to-buy-car-in-connecticut-guid': [post33, post37, post38],
  'how-to-buy-car-in-maryland-guid': [post35, post36],
  'car-financing-in-maryland-2025': [post37, post36],
  'car-financing-in-california-2025': [post36, post37, post34],
  'how-to-buy-a-car-us-international-student': [post20, post21, post22],
};

export const relatedPostsWithoutTitle = [post25];
