import Image from 'next/image';
import { Paragraph, H1, H2, ImageWrap } from '../styles';
import { Post } from '../types';
import { BRAND_NAME } from 'shared/utils/constants';
import postImage from '/public/img/blog/blog-intro-post-image.png';
import BlogCTAButton from 'components/atoms/BlogCTAButton';

const post: Post = {
  date: '03/19/2023',
  slug: 'introducing-lease-bandit-your-gateway-to-car-leasing-at-unbeatable-prices',
  tabTitle: `Introducing ${BRAND_NAME}: Your Gateway to Car Leasing at Unbeatable Prices &#8211; Get away with your dream car | ${BRAND_NAME}`,
  title: `Introducing ${BRAND_NAME}: Your Gateway to Car Leasing at Unbeatable Prices`,
  summary: `If you’re a first-time visitor to ${BRAND_NAME}’s website, you’re in for a treat. This innovative company was co-founded by three passionate car enthusiasts who shared a common goal: to lease vehicles at affordable prices. With their extensive knowledge of the leasing process, they’ve created a platform that brings transparency to car leasing, allowing you…`,
  image: postImage.src,
  Component: () => {
    return (
      <div>
        <H1 gutterBottom variant='h1'>
          Introducing {BRAND_NAME}: Your Gateway to Car Leasing at Unbeatable
          Prices
        </H1>
        <ImageWrap>
          <Image height={406} width={651} src={postImage.src} alt='' />
        </ImageWrap>
        <Paragraph>
          If you’re a first-time visitor to {BRAND_NAME}’s website, you’re in
          for a treat. This innovative company was co-founded by three
          passionate car enthusiasts who shared a common goal: to lease vehicles
          at affordable prices. With their extensive knowledge of the leasing
          process, they’ve created a platform that brings transparency to car
          leasing, allowing you to secure an incredible deal too!
        </Paragraph>

        <ImageWrap>
          <Image
            height={584}
            width={652}
            src='/img/blog/blog-intro-image-2.png'
            alt=''
          />
        </ImageWrap>

        <BlogCTAButton
          href='/deals'
          text={`Explore Lease Options at ${BRAND_NAME}`}
        />

        <H2 variant='h2'>
          <b>
            So, what exactly does {BRAND_NAME} do, and why should you choose
            them?
          </b>
        </H2>

        <Paragraph>
          {BRAND_NAME} is an online car auction service with a mission to
          simplify the leasing process and make car leases accessible to the
          average consumer. By partnering with local car dealerships, they offer
          a curated inventory of specific cars available for bidding. The best
          part? The price range is customized to suit your preferences, ensuring
          you find the perfect vehicle within your budget. What sets Lease
          Bandit apart from other leasing platforms is their integration of
          personal incentives into your final bid price. Whether you’re eligible
          for brand loyalty discounts, EV incentives, healthcare worker
          benefits, military discounts, or educator rewards, these incentives
          are seamlessly integrated, guaranteeing you the best possible lease
          deal.
        </Paragraph>

        <ImageWrap>
          <Image
            height={432}
            width={733}
            src='/img/blog/blog-intro-image-3.png'
            alt=''
          />
        </ImageWrap>

        <BlogCTAButton
          href='/deals?dealType=finance'
          text='Get a Personalized Financing Plan'
        />

        <Paragraph variant='h6'>
          <b>
            Now, let’s talk about the convenience {BRAND_NAME} brings to the
            table.
          </b>
        </Paragraph>

        <Paragraph>
          Picture this: You can bid, win, and drive home your dream vehicle
          without ever leaving your couch. The online platform eliminates the
          need for additional negotiations, streamlining the process for you. If
          you prefer to skip the bidding competition altogether, you can utilize
          the “Lease it Now” option and secure your lease instantly. The choice
          is yours, but rest assured that either way, you’ll end up with a
          fantastic lease deal.
        </Paragraph>

        <Paragraph>
          {BRAND_NAME} understands that leasing a vehicle shouldn’t be a
          complicated and time-consuming endeavor. With their user-friendly
          platform, they’ve simplified the process, allowing you to lease your
          dream car with ease. Whether you’re a car enthusiast or someone who
          simply appreciates the finer things in life, {BRAND_NAME} offers a
          hassle-free solution to make your car leasing experience
          unforgettable.
        </Paragraph>
        <Paragraph>
          <b>
            Ready to embark on your journey towards affordable dream car
            lease?&nbsp;
          </b>
          Visit {BRAND_NAME}’s website now and explore their impressive
          inventory waiting to be leased. Bid, win, and drive home your dream
          car today!
        </Paragraph>
        <BlogCTAButton href='/' text='Find Leasing & Financing Options' />
      </div>
    );
  },
};

export default post;
