import Box from '@mui/material/Box';
import { StyledButtonMui5 } from './StyledButton';
import { ButtonPropsColorOverrides } from '@mui/material/Button';
import { OverridableStringUnion } from '@mui/types';

interface Props {
  text: string;
  color?: OverridableStringUnion<
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning',
    ButtonPropsColorOverrides
  >;
  href: string;
}

const BlogCTAButton = ({
  text,
  href,
  color = 'secondary',
}: Props): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <StyledButtonMui5 color={color} href={href}>
        {text}
      </StyledButtonMui5>
    </Box>
  );
};
export default BlogCTAButton;
